import React, { useState, useEffect } from 'react';
import ArrowSvg from './shared/arrow.svg';

const MembershipCard = ({ pack, handleChange, values, checkbox, Continue, organization_details }) => {
  var { selected_package, subscription_meta } = values
  const [activeTab, setActiveTab] = useState('Memberships');


  const getMembershipSubscriptionMeta = (membershipSubscriptionId) => {
    if (subscription_meta && subscription_meta.membership_subscriptions && subscription_meta.membership_subscriptions.length > 0) {
      for (var membershipSubscriptionMeta of subscription_meta.membership_subscriptions) {
        if (membershipSubscriptionMeta.id === membershipSubscriptionId) {
          return membershipSubscriptionMeta
        }
      }
    }
    return null
  }

  const renderMembershipSubscriptionValidity = (subscription) => {
    if (subscription.duration_days) {

      let membershipSubscriptionMeta = getMembershipSubscriptionMeta(subscription.id)

      return <>
        <div className="col-3">
          <dl>
            <dt>Valid for</dt>
            <dd>{subscription.duration_days} days</dd>
          </dl>
        </div>

        {membershipSubscriptionMeta &&
          <div className="col-3">
            <dl>
              <dt>Start date</dt>
              <dd>{membershipSubscriptionMeta.membership_start_date}</dd>
            </dl>
          </div>
        }

      </>
    } else {
      return <>
        <div className="col-3">
          <dl>
            <dt>Valid from</dt>
            <dd>{new Date(subscription.membership.valid_from).toISOString().split('T')[0]}</dd>
          </dl>
        </div>
        <div className="col-3">
          <dl>
            <dt>Valid to</dt>
            <dd>{new Date(subscription.membership.valid_to).toISOString().split('T')[0]}</dd>
          </dl>
        </div>
      </>
    }
  }



  const Tab = ({ activeTab, label, onClick }) => (
    <li className="nav-item" role="presentation">
      <button className={activeTab === label ? 'active nav-link' : 'nav-link'} onClick={() => onClick(label)}
        data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-selected={activeTab === label ? "true" : "false"}>{label}</button>
    </li>
  );

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };



  // const toggleLines = (id, event) => {
  //   event.preventDefault();
  //   var pack = document.getElementById('pack-' + id);
  //   var dots = pack.getElementsByClassName("dots")[0];
  //   var moreText = pack.getElementsByClassName("more")[0];
  //   var btnText = pack.getElementsByClassName("myBtn")[0];
  //   var shortText = pack.getElementsByClassName("short")[0];

  //   if (dots.style.display === "none") {
  //     dots.style.display = "inline";
  //     btnText.innerHTML = "Show More";
  //     moreText.style.display = "none";
  //     shortText.style.display = "inline";
  //   } else {
  //     dots.style.display = "none";
  //     btnText.innerHTML = "Show Less";
  //     moreText.style.display = "inline";
  //     shortText.style.display = "none";
  //   }
  // }

  const handleSelectedPackage = (e) => {
    const packId = e.currentTarget.getAttribute('data-pack-id');

    handleChange('selected_package', parseInt(packId))
    Continue(e, parseInt(packId))
  }


  return (
    <>
      {pack && pack.membershipSubscriptions && pack.membershipSubscriptions.length > 0 &&
        <div className="container membership packages">
          <div className="row overflow-hidden">
            <div className="col-12 mx-auto" id={'pack-' + pack.id}>

              {selected_package &&
                   <div className="row overflow-hidden"><div className="col-12 mx-auto">
                    <div className="card mw-container">
                      {organization_details && organization_details.image_url && <img src={organization_details.image_url} className="w-100" alt="" />}
                      <div className="card-body bg-white d-flex justify-content-between align-items-center ">
                        <div>
                          <h6 className="card-title">{pack.name && pack.name}</h6>
                          <span className="price">
                            {pack.price && parseFloat(pack.price / 100).toFixed(2)}{' '}{pack.currency}
                            <b><small>(Inc. VAT)</small></b>
                          </span>
                        </div>
                        {organization_details && organization_details.logo_image_url && <img src={organization_details.logo_image_url} height="65" alt="logo" className="unionLogo" />}
                      </div>
                    </div>
                  </div>
                  </div>
               }


              <div className="card mw-container packages-item">

                {!selected_package && organization_details &&
                  <a href={'/' + organization_details.organization_id + '/packages/' + pack.id}>
                    <div className="card-body align-items-center d-flex justify-content-between">
                      <div>
                        <h6 className="card-title">{pack.name && pack.name}</h6>
                        <span className="price">
                          {pack.price && parseFloat(pack.price / 100).toFixed(2)}{' '}{pack.currency}
                        </span>
                      </div>

                      {checkbox &&
                        <div className="form-check">
                          {!selected_package && <img src={ArrowSvg} alt="Arrow" />}
                        </div>}
                    </div>
                  </a>
                }



                <ul className="nav nav-pills" id="myTab" role="tablist">
                  <Tab activeTab={activeTab} label="Memberships" onClick={handleTabClick} />
                  {pack.description && <Tab activeTab={activeTab} label="About" onClick={handleTabClick} />}
                </ul>

                {activeTab === 'About' && (
                  <div className="card-footer">
                    <div className="card-text">
                      <div dangerouslySetInnerHTML={{ __html: pack.description }} />
                    </div>
                  </div>)
                }

                {activeTab === 'Memberships' &&
                  <div className="card-footer">
                    {pack && pack.membershipSubscriptions && pack.membershipSubscriptions.length > 0 &&
                      pack.membershipSubscriptions.map(function (subscription, i) {
                        return <div className="row" key={i}>
                          <div className="col-6">
                            <dl>
                              <dt>Membership</dt>
                              <dd>{subscription.membership.name}</dd>
                            </dl>
                          </div>
                          {renderMembershipSubscriptionValidity(subscription)}
                        </div>
                      })
                    }
                  </div>}

              </div>
            </div>
          </div>
        </div >
      }
    </>
  )

}

export default MembershipCard