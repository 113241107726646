import React, { useState, useEffect } from 'react'
import Header from './shared/Header';
import { useLocation } from 'react-router-dom';
import { getTransactionById } from '../api/apiHelper'
import MembershipCard from './MembershipCard';
import { SUCCESS } from './shared/Steps';
import EventCard from './EventCard';
import checkSvg from './shared/check.svg';

const SuccessPayment = () => {
  const [transaction, setTransaction] = useState();
  const [subscriptionMeta, setSubscriptionMeta] = useState();

  const [packageOrEvent, setPackageOrEvent] = useState();
  const [logOutPath, setLogOutPath] = useState();
  const [ticketTransaction, setTicketTransaction] = useState();

  const [member, setMember] = useState();

  const [scope, setScope] = useState();

  const [callDone, setCallDone] = useState(false);

  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const payment_intent = params.get("payment_intent")

  useEffect(() => {
    getTransactionById(payment_intent, (data) => {

      if (data) {

        if (data.ticket_transactions && data.ticket_transactions.length > 0) {

          setMember(data.ticket_transactions[0].member)

          setPackageOrEvent(data.ticket_transactions[0].tickets.organization_event_occurrences[0].organization_event)
          setSubscriptionMeta(JSON.parse(data.ticket_transactions[0].meta))

          setTicketTransaction(data.ticket_transactions[0])

          setLogOutPath(data.ticket_transactions[0].tickets.organization_event_occurrences[0].organization_event.organization_id + '/events')
          setScope('ticket');

        } else if (data.package_transactions && data.package_transactions.length > 0) {

          setSubscriptionMeta(JSON.parse(data.package_transactions[0].meta))
          setPackageOrEvent(data.package_transactions[0].package)

          setLogOutPath(data.package_transactions[0].package.organization_id)

          setScope('package');
          setMember(data.package_transactions[0].member)

        } else {
          // err?
        }

        setTransaction(data)
      } else {
        setTransaction(null)
      }
      setCallDone(true)
    }, (error) => {
      setTransaction(null)
      setCallDone(true)
    })
  }, [])


  const renderPackages = (e) => {
    return <MembershipCard pack={packageOrEvent} key={packageOrEvent.id} values={{ selected_package: packageOrEvent.id, subscription_meta: subscriptionMeta }} checkbox={false} />
  }


  // TODO DEV-2355 error?
  const renderTickets = (e) => {
    // TODO DEV-2355 selected_occurrence!!! should be an array!!
    return <EventCard event={packageOrEvent} values={{ selected_ticket: ticketTransaction.tickets, ticket: ticketTransaction.tickets, selected_occurrence: ticketTransaction.tickets.organization_event_occurrences[0], meta: subscriptionMeta }} count={false} checkbox={false} markInactive={false} />
  }

  return (
    <>
      {transaction && transaction !== undefined && Object.keys(transaction).length > 0 && !transaction.error ?
        <>
          <Header title="Thank you!" first_name={member.first_name} step={SUCCESS} orgId={logOutPath} />
          <div className="container mb-3 mw-container mt-5 text-center">

            {/* /**
            * transaction.status:
            * 0 - payment started/init
            * 1 - payment processing
            * 2 - payment failed
            * 3 - payment succeeded
            * 4 - payment succeeded & fulfilled
            * 
            * 5 - refund pending
            * 6 - refund failed
            * 7 - refund success
            */}
            {transaction.status === 0 ?
              <>
                <img src={checkSvg} width={60} alt="check" title="check"  className='checkImg'/>
                <h4><strong>Payment complete!</strong></h4>
              </>
              : transaction.status === 1 ?
                <>
                  <img src={checkSvg} width={60} alt="check" title="check" className='checkImg' />
                  <h4><strong>Payment complete!</strong></h4>
                </>
                : transaction.status === 2 ?
                  <>
                    <h4><strong>Payment failed!</strong></h4>
                  </>
                  : transaction.status === 3 || transaction.status === 4 ?
                    <>
                      <img src={checkSvg} width={60} alt="check" title="check" className='checkImg' />
                      <h4><strong>Payment successful!</strong></h4>
                    </>
                    : transaction.status === 5 ?
                      <>
                        <h4><strong>Refund pending!</strong></h4>
                      </>
                      : transaction.status === 6 ?
                        <>
                          <h4><strong>Refund failed!</strong></h4>
                        </>
                        : transaction.status === 7 ?
                          <>
                            <img src={checkSvg} width={35} alt="check" title="check" />
                            <h4><strong>Refund successful!</strong></h4>
                          </>
                          :
                          <>
                            <h4><strong>Payment Unknown Status!</strong></h4>
                          </>
            }

            <p className="txnumber">Transaction number: {transaction.transaction_id}</p>

            {
            scope === 'ticket' && [0,1,3,4].includes(transaction.status) &&
              <p><b>Thank you for your purchase. Your receipt and tickets will be sent to your email within the next 5 minutes.</b></p>
            }

          </div>
          <div className="container membership">
            <div className="card mw-container mx-auto mt-4">
              <div className="card-footer">
                <div className="row">
                  <div className="col-6">
                    <dl>
                      <dt>Name:</dt>
                      <dd>{member.first_name}{' '}{member.last_name}</dd>
                    </dl>
                  </div>
                  <div className="col-6">
                    <dl>
                      <dt>Personal Number:</dt>
                      <dd>{member.personal_identification_number}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {scope === 'ticket' ? renderTickets() : renderPackages()}

        </>
        : callDone && transaction && transaction !== undefined && Object.keys(transaction).length > 0 && transaction.error ?
          <>
            <Header title="Not Found!" />
            <div className="container mb-5 mw-container mt-5 text-center">
              <h4>Transaction not found!</h4>
            </div>
          </>
          : callDone &&
          <>
            <Header title="Not Found!" />
            <div className="container mb-5 mw-container mt-5 text-center">
              <h4>Something went wrong. Please try again later.</h4>
            </div>
          </>
      }
    </>
  )
}

export default SuccessPayment
